<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import {
  shopServiceMethods,
  notificationMethods
} from "@/state/helpers";

import {
  required, 
} from "vuelidate/lib/validators";

import Multiselect from 'vue-multiselect'

import { rolesService } from '../../../services/roles.service';

const initForm = {description: "",title:"", accessRights:[],createdBy: JSON.parse(localStorage.getItem('user')).id};


export default {
  page: {
    title: "Roles ",
    meta: [{ name: "description", content: appConfig.description }]
  },

  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      allRoles: [],
      title: "Roles",
      values: [],
  
      options: [],
      updateSuccess:false,
      updateFailed:false,
      editedRole:{},
      items: [
        {
          text: "Smart Exchange",
          href: "/"
        },
        {
          text: "Roles",
          active: true
        }
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { key: "id", sortable: true },
        { key: "title", sortable: true },
        { key: "description", sortable: true },
        { key: "Actions", sortable: false },
      ],

      form: {
        description: null,
        title: null,
        accessRights:[],
        createdBy: JSON.parse(localStorage.getItem('user')).id,
      },

      editForm: {
        id:null,
        description: null,
        title: null,
        accessRights:[],
        createdBy: JSON.parse(localStorage.getItem('user')).id,
      },


      submitted: false,
      submitform: false,
      submit: false,
      showform: false
    };
  },

  validations: {
    form: {
      description: { required },
      title: {required},
    }
  },

  created() {
    this.loadAllRoles();
    this.loadAllSystemFunctions()
  },

  computed: {
    rows() {
      return this.allRoles.length;
    },
    notification() {
        return this.$store ? this.$store.state.notification : null;
      }
  },
  mounted() {
    this.totalRows = this.items.length;
  },

  methods: { 
    ...shopServiceMethods,
    ...notificationMethods,

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    formSubmit() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitted = false;
        return;
      } else {
          const {form} = this;
          var accessRights = [];
          this.values.forEach(sysFunction => {
            if(sysFunction.id!=="6"){
              //Ignore Branch liquidation
              accessRights.push(sysFunction.id);
            }
          });

          form.accessRights = accessRights;
          const roleForm = {
                "operation" : "Add New Role",
                "accessToken" : JSON.parse(localStorage.getItem('access_token')),
                "requestBody" : form
          }; 
          rolesService.addRole(roleForm).then(result=>{
            if(result.status=="SUCCESS"){
              this.loadAllRoles()
              this.notificationType = "success";
              this.noticationIcon = "mdi-check-all";
              this.submitted = false;
              this.form = Object.assign({}, initForm);
              this.values = [];
            } else {
               this.submitted = false;
              this.notificationType = "danger";
              this.noticationIcon = "mdi-block-helper";
            }
          });
      }
    },


    formEdit() {
      this.submitted = true;
          const {editForm} = this;
          var accessRights = [];
          this.editForm.accessRights.forEach(sysFunction => {
            if(sysFunction.id!=="6"){
              accessRights.push(sysFunction.id);
            }
          });

          editForm.accessRights = accessRights;
          const roleForm = {
                "operation" : "Edit Role",
                "accessToken" : JSON.parse(localStorage.getItem('access_token')),
                "requestBody" : editForm
          }; 
          rolesService.updateRole(roleForm).then(result=>{
            if(result.status=="SUCCESS"){
              this.loadAllRoles()
              this.notificationType = "success";
              this.noticationIcon = "mdi-check-all";
              this.submitted = false;
              this.updateSuccess = true;
              this.editForm = Object.assign({}, initForm);
              this.values = [];
            } else {
              this.submitted = false;
              this.updateFailed = true;
              this.notificationType = "danger";
              this.noticationIcon = "mdi-block-helper";
            }
          });
      
    },

    storeState(selectedRole){

      this.editForm.accessRights = selectedRole.item.systemFunctions;
      this.editForm.id = selectedRole.item.id;
      this.editForm.description = selectedRole.item.description;
      this.editForm.title = selectedRole.item.title;
    
    },

    async loadAllRoles() {
      const rowsToBeRemoved = ["-1"];
        try {
        await rolesService.getAllRoles().then(response=>{
            if(response.responseBody.length>0){

              rowsToBeRemoved.forEach(element => {
                const indexOfObject = response.responseBody.findIndex(object => {
                  return object.id == element;
                });
                response.responseBody.splice(indexOfObject, 1);
              });
                this.allRoles = response.responseBody;
              }
          });
        } catch (error) {
          console.log(error);
        }
    },

    async loadAllSystemFunctions() {
        try {
        await rolesService.getSystemFunctions().then(response=>{
            if(response.responseBody.length>0){
                this.options = response.responseBody;
              }
          });
        } catch (error) {
          console.log(error);
        }
    },
  },

};

</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <b-modal id="modal-standard" title="Update Role" title-class="font-18" hide-footer>
        <h5  class="card-title badge-soft-danger pb-2 pt-2 pl-2" v-if="updateFailed">
          <i :class="'mdi mdi-block-helper mr-2'"></i>{{responseMessage}}
        </h5>
        <h5  class="card-title badge-soft-success pb-2 pt-2 pl-2" v-if="updateSuccess">
          <i :class="'mdi mdi-check-all mr-2'"></i>{{responseMessage}}
        </h5>

        <form class="needs-registration" @submit.prevent="formEdit">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="validationCustom03">Role Title </label>
                    <div>
                      <input
                        id="validationCustom03"
                        v-model="editForm.title"
                        type="text"
                        class="form-control"
                        placeholder="Enter the role title"
                      />
                      
                    </div>
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="form-group">
                    <label for="validationCustom03">Description </label>
                    <div>
                      <input
                        id="validationCustom03"
                        v-model="editForm.description"
                        type="text"
                        class="form-control"
                        placeholder="Enter the description"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="validationCustom03">Permissions </label>
                    <div>
                      <multiselect 
                      v-model="editForm.accessRights" 
                      :options="options" 
                      :multiple="true" 
                      :close-on-select="false" 
                      :clear-on-select="false" 
                      :preserve-search="false" 
                      placeholder="Select permisions" 
                      label="title" 
                      track-by="title" 
                      :preselect-first="true"
                      >
                    </multiselect>
                    </div>
                  </div>
                </div>
              </div>
              <div class="spinner" v-if="submitted">
                <i class="ri-loader-line spin-icon"></i>
              </div>
              <button v-if="!submitted" class="btn btn-primary" type="submit">Edit Role</button>
            </form>

    </b-modal>
    <div class="row">
      <div class="col-xl-5">
        <b-role
          :variant="notificationType" 
          class="mt-3"
          v-if="notification.message"
          show
          dismissible
        >
          <i :class="'mdi '+ noticationIcon + ' mr-2'"></i>{{notification.message}}
        </b-role>
          <a href="javascript:void(0);" class="btn btn-primary mb-2" @click="showform = true" v-if="!showform">
            <i class="mdi mdi-plus mr-2"></i> Add New System Access Role
          </a>

        <div class="card" v-if="showform">
          <div class="card-body">
            <form class="needs-registration" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="validationCustom03">Role Title </label>
                    <div>
                      <input
                        id="validationCustom03"
                        v-model="form.title"
                        type="text"
                        class="form-control"
                        placeholder="Enter the role title"
                        :class="{ 'is-invalid': submitted && $v.form.title.$error }"
                      />
                      <div v-if="submitted && $v.form.title.$error" class="invalid-feedback">
                        <span v-if="!$v.form.title.required">This value is required.</span> 
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="form-group">
                    <label for="validationCustom03">Description </label>
                    <div>
                      <input
                        id="validationCustom03"
                        v-model="form.description"
                        type="text"
                        class="form-control"
                        placeholder="Enter the description"
                        :class="{ 'is-invalid': submitted && $v.form.description.$error }"
                      />
                      <div v-if="submitted && $v.form.description.$error" class="invalid-feedback">
                        <span v-if="!$v.form.description.required">This value is required.</span> 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="validationCustom03">Permissions </label>
                    <div>
                      <multiselect 
                      v-model="values" 
                      :options="options" 
                      :multiple="true" 
                      :close-on-select="false" 
                      :clear-on-select="false" 
                      :preserve-search="false" 
                      placeholder="Select permisions" 
                      label="title" 
                      track-by="title" 
                      :preselect-first="true"
                      >
                    </multiselect>
                      <div v-if="submitted && $v.form.description.$error" class="invalid-feedback">
                        <span v-if="!$v.form.description.required">Please select permission is required.</span> 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="spinner" v-if="submitted">
                                <i class="ri-loader-line spin-icon"></i>
                            </div>
              <button v-if="!submitted" class="btn btn-primary" type="submit">Add Role</button>
            </form>
          </div>
        </div>
      </div>
       <div class="col-xl-7">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="allRoles"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
              <template #cell(actions)="row">
                <a
                  @click="storeState(row)"
                  href="javascript:void(0);"
                  class="mr-3 text-primary"
                  v-b-tooltip.hover
                  title="Edit"
                >
                  <i v-b-modal.modal-standard class="mdi mdi-pencil font-size-18" ></i>
                </a>


              </template>
      </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </Layout>
</template>